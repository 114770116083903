<template>
  <sun-form class="text-sm" @submit="uploadCostView">
    <asterix-modal title="Upload File" :modal-class="modalWidth" @cancel="$emit('cancel')">
      <template #content>
        <div class="flex flex-row flex-wrap text-left justify-betwen items-center max-h-screen overflow-auto w-full">
          <div
            class="w-full"
            :class="{
              'lg:w-1/2 h-full lg:mb-0 mb-5 \
              rounded overflow-auto content-div h-3/5 pr-5': showPreview,
            }"
          >
            <sun-label-group text="Platform" class="text-left block ml-2 mb-4">
              <asterix-async-select
                id="advertiser-select"
                name="select-advertiser"
                class="advertiser-filter"
                add-hex-color="orange"
                track-by="id"
                label="name"
                class-input="pt-0 shadow-none rounded-none multiselect"
                :items-per-page="100"
                required="required"
                text-error="Platform is required"
                :service="apiService"
                data-testid="advertiser-select"
                @change="selectPlatform"
              />
            </sun-label-group>
            <sun-label-group text="File" class="text-left block ml-2 mb-2">
              <div class="w-full">
                <sun-file-uploader
                  :accept="acceptedFiles"
                  @change="clickFile"
                  @reset="deleteFile"
                  @delete-file="deleteFile"
                  @error="wrongFile"
                />
              </div>
            </sun-label-group>
          </div>
          <div
            v-show="showPreview"
            class="border-t-2 lg:border-l-2 lg:border-t-0 lg:w-1/2 w-full border-gray-200 overflow-auto h-3/5 pt-2"
          >
            <sun-label-group text="File preview" class="text-left mb-2 lg:ml-5 flex content-center">
              <div class="w-full">
                <sun-data-table
                  :headers="headers"
                  :content="previewItems"
                  hoverable
                  narrowed
                  :loading="loading"
                  native-scroll
                  sticky-header
                  class="w-full preview-table"
                >
                  <template #[`col.date`]="{ item }">
                    <sun-data-table-cell class="text-center">
                      <span class="text-gray-700 text-xs inline-flex">
                        {{ item.date }}
                      </span>
                    </sun-data-table-cell>
                  </template>

                  <template #[`col.cost`]="{ item }">
                    <sun-data-table-cell class="text-center">
                      <span class="text-gray-700 text-xs">{{ item.cost }}</span>
                    </sun-data-table-cell>
                  </template>
                  <template #empty>
                    <asterix-no-data class="bg-white" />
                  </template>
                </sun-data-table>
              </div>
            </sun-label-group>
          </div>
        </div>
      </template>

      <template #footer>
        <div class="sm:px-3 sm:flex sm:flex-row mb-3">
          <div class="flex w-full rounded-md shadow-sm sm:mr-3 sm:w-auto">
            <sun-button
              variant="pill"
              type="submit"
              class="custom-p-1 text-xs w-full"
              color="orange"
              data-test="sendBtn"
              :disabled="!canSubmit"
            >
              Upload
            </sun-button>
          </div>
          <div class="mt-3 flex w-full rounded-md shadow-sm sm:mt-0 sm:w-auto">
            <sun-button variant="pill" class="custom-p-1 text-xs w-full" color="white" @click="$emit('cancel')">
              Cancel
            </sun-button>
          </div>
        </div>
      </template>
    </asterix-modal>
  </sun-form>
</template>

<script>
import { mapActions } from 'vuex';
import csvParser from 'papaparse';
import AsterixModal from '@/components/organisms/shared/AsterixModal';
import { getNotConnectedPlatforms } from '@/services/modules/ThirdParty/platforms';
import { uploadCostFile } from '@/services/modules/ThirdParty/cost';
import { indexMixin } from '@/mixins/index/indexMixin';
import { Toast } from '@/model/shared/Toast';
import { CREATE_TOAST } from '@/store/modules/toast/keys';
import AsterixAsyncSelect from '@/components/molecules/shared/AsterixAsyncSelect';

export default {
  name: 'CostDataLoadModal',
  components: {
    AsterixAsyncSelect,
    AsterixModal,
    AsterixNoData: () => import('@/components/organisms/shared/AsterixNoData'),
  },
  mixins: [indexMixin],
  data: () => ({
    selectedPlatform: null,
    items: [],
    acceptedFiles: '.csv',
    costFileError: false,
    costFile: null,
    loading: true,
    previewItems: [],
    headers: [
      { name: 'Date', value: 'date', class: 'text-center' },
      { name: 'Cost', value: 'cost', class: 'text-center' },
    ],
  }),
  computed: {
    canSubmit() {
      return !!(this.selectedPlatform && this.costFile && !this.costFileError);
    },
    showPreview() {
      return !!(this.costFile && !this.costFileError);
    },
    modalWidth() {
      return this.showPreview ? 'sm:max-w-5xl' : 'sm:max-w-lg';
    },
  },
  created() {
    this.filterDefault = [{ name: 'client.id', value: this.activeClient?.id }];
    this.getTableItems();
  },
  methods: {
    ...mapActions({ createToast: CREATE_TOAST }),

    apiService(params) {
      params.addFilter('client.id', this.activeClient.id);
      return getNotConnectedPlatforms(params);
    },
    selectPlatform(event) {
      this.selectedPlatform = event?.items;
    },
    clickFile(file) {
      if (!file.length) return;
      this.costFile = file?.[0];
      this.costFileError = false;
      this.parserFile();
    },
    parserFile() {
      const self = this;
      csvParser.parse(this.costFile, {
        complete(results) {
          self.showFilePreview(results.data);
        },
      });
    },
    showFilePreview(file) {
      const firstColumnHeader = file[0][0].toLowerCase();
      const secondColumnHeader = file[0][1].toLowerCase();
      if (firstColumnHeader !== 'date' || secondColumnHeader !== 'cost') {
        this.costFileError = true;
        this.createToast(Toast.error('File could not be previewed', 'Invalid format file'));
        return;
      }
      // Delete headers from array parse: first row
      file.splice(0, 1);
      this.previewItems = this.formatResultDataParser(file);
      this.loading = false;
    },
    formatResultDataParser(data) {
      return data.map(el => ({ date: el[0], cost: el[1] }));
    },
    async uploadCostView() {
      if (!this.costFile) return;
      if (this.costFileError) {
        this.createToast(Toast.error('File not uploaded', 'Please upload a valid file'));
        return;
      }
      try {
        await uploadCostFile(this.selectedPlatform, this.costFile);
        this.createToast(Toast.success('File uploaded', 'File was uploaded successfully'));
        this.$emit('cancel');
      } catch (e) {
        this.createToast(Toast.error('File could not be uploaded', e.message));
      }
    },
    deleteFile() {
      this.costFile = null;
    },
    wrongFile() {
      this.costFileError = true;
    },
    async getTableItems() {
      this.isLoading = true;
      const { data, isCancel } = await this.getItemsFromAPI(getNotConnectedPlatforms);
      this.items = data;
      if (!isCancel) {
        this.isLoading = false;
      }
    },
  },
};
</script>

<style scoped>
::v-deep .p-1.flex.flex-row.items-start.max-w-full.p-2 {
  display: none;
  padding: 0;
}

.form-button {
  max-width: 75px;
}

.preview-table {
  height: 20rem;
}
</style>
