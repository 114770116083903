var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "sun-form",
    { staticClass: "text-sm", on: { submit: _vm.uploadCostView } },
    [
      _c("asterix-modal", {
        attrs: { title: "Upload File", "modal-class": _vm.modalWidth },
        on: {
          cancel: function ($event) {
            return _vm.$emit("cancel")
          },
        },
        scopedSlots: _vm._u([
          {
            key: "content",
            fn: function () {
              return [
                _c(
                  "div",
                  {
                    staticClass:
                      "flex flex-row flex-wrap text-left justify-betwen items-center max-h-screen overflow-auto w-full",
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "w-full",
                        class: {
                          "lg:w-1/2 h-full lg:mb-0 mb-5 \
            rounded overflow-auto content-div h-3/5 pr-5": _vm.showPreview,
                        },
                      },
                      [
                        _c(
                          "sun-label-group",
                          {
                            staticClass: "text-left block ml-2 mb-4",
                            attrs: { text: "Platform" },
                          },
                          [
                            _c("asterix-async-select", {
                              staticClass: "advertiser-filter",
                              attrs: {
                                id: "advertiser-select",
                                name: "select-advertiser",
                                "add-hex-color": "orange",
                                "track-by": "id",
                                label: "name",
                                "class-input":
                                  "pt-0 shadow-none rounded-none multiselect",
                                "items-per-page": 100,
                                required: "required",
                                "text-error": "Platform is required",
                                service: _vm.apiService,
                                "data-testid": "advertiser-select",
                              },
                              on: { change: _vm.selectPlatform },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "sun-label-group",
                          {
                            staticClass: "text-left block ml-2 mb-2",
                            attrs: { text: "File" },
                          },
                          [
                            _c(
                              "div",
                              { staticClass: "w-full" },
                              [
                                _c("sun-file-uploader", {
                                  attrs: { accept: _vm.acceptedFiles },
                                  on: {
                                    change: _vm.clickFile,
                                    reset: _vm.deleteFile,
                                    "delete-file": _vm.deleteFile,
                                    error: _vm.wrongFile,
                                  },
                                }),
                              ],
                              1
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.showPreview,
                            expression: "showPreview",
                          },
                        ],
                        staticClass:
                          "border-t-2 lg:border-l-2 lg:border-t-0 lg:w-1/2 w-full border-gray-200 overflow-auto h-3/5 pt-2",
                      },
                      [
                        _c(
                          "sun-label-group",
                          {
                            staticClass:
                              "text-left mb-2 lg:ml-5 flex content-center",
                            attrs: { text: "File preview" },
                          },
                          [
                            _c(
                              "div",
                              { staticClass: "w-full" },
                              [
                                _c("sun-data-table", {
                                  staticClass: "w-full preview-table",
                                  attrs: {
                                    headers: _vm.headers,
                                    content: _vm.previewItems,
                                    hoverable: "",
                                    narrowed: "",
                                    loading: _vm.loading,
                                    "native-scroll": "",
                                    "sticky-header": "",
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: `col.date`,
                                        fn: function ({ item }) {
                                          return [
                                            _c(
                                              "sun-data-table-cell",
                                              { staticClass: "text-center" },
                                              [
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "text-gray-700 text-xs inline-flex",
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(item.date) +
                                                        " "
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            ),
                                          ]
                                        },
                                      },
                                      {
                                        key: `col.cost`,
                                        fn: function ({ item }) {
                                          return [
                                            _c(
                                              "sun-data-table-cell",
                                              { staticClass: "text-center" },
                                              [
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "text-gray-700 text-xs",
                                                  },
                                                  [_vm._v(_vm._s(item.cost))]
                                                ),
                                              ]
                                            ),
                                          ]
                                        },
                                      },
                                      {
                                        key: "empty",
                                        fn: function () {
                                          return [
                                            _c("asterix-no-data", {
                                              staticClass: "bg-white",
                                            }),
                                          ]
                                        },
                                        proxy: true,
                                      },
                                    ],
                                    null,
                                    true
                                  ),
                                }),
                              ],
                              1
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                  ]
                ),
              ]
            },
            proxy: true,
          },
          {
            key: "footer",
            fn: function () {
              return [
                _c("div", { staticClass: "sm:px-3 sm:flex sm:flex-row mb-3" }, [
                  _c(
                    "div",
                    {
                      staticClass:
                        "flex w-full rounded-md shadow-sm sm:mr-3 sm:w-auto",
                    },
                    [
                      _c(
                        "sun-button",
                        {
                          staticClass: "custom-p-1 text-xs w-full",
                          attrs: {
                            variant: "pill",
                            type: "submit",
                            color: "orange",
                            "data-test": "sendBtn",
                            disabled: !_vm.canSubmit,
                          },
                        },
                        [_vm._v(" Upload ")]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    {
                      staticClass:
                        "mt-3 flex w-full rounded-md shadow-sm sm:mt-0 sm:w-auto",
                    },
                    [
                      _c(
                        "sun-button",
                        {
                          staticClass: "custom-p-1 text-xs w-full",
                          attrs: { variant: "pill", color: "white" },
                          on: {
                            click: function ($event) {
                              return _vm.$emit("cancel")
                            },
                          },
                        },
                        [_vm._v(" Cancel ")]
                      ),
                    ],
                    1
                  ),
                ]),
              ]
            },
            proxy: true,
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }